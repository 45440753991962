import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import { componentFromStream, compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const enhancer = compose(
  getContext({
    getCachedCustomer: PropTypes.func,
    getCustomerPredictions: PropTypes.func,
  }),
);

const CustomerAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ input: { value } }) =>
        value && value.id && !value.name
          ? getCachedCustomer(value.id)
              .map(item => ({ id: value.id, name: item.get("full_name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .switchMap(
        ({ isHybrid, merchantType, hasMerchantInn, input: { value } }) =>
          getCustomerPredictions(
            isHybrid
              ? baseFilter
                  .setValue("source", "HYBRID")
                  .setSearch(value && value.name)
              : baseFilter
                  .setSearch(value && value.name)
                  .setValue("has_merchant_inn", hasMerchantInn)
                  .setValue("merchant_type", merchantType),
          )
            .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
            .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      ({ readOnly, ...props }, value, options) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={readOnly}
        />
      ),
    );
  }),
);

FormCustomerAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  hasMerchantInn: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  placeholder: PropTypes.string,
  merchantType: PropTypes.string,
  label: PropTypes.node,
};

FormCustomerAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormCustomerAutoComplete(props) {
  return <Field {...props} component={CustomerAutoComplete} />;
}
